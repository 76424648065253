export default [
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Programs',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'value',
    cols: 12,
    placeholder: '--Select program --',
    visible: true,
  },

  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Merchants',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'description',
    cols: 12,
    placeholder: '--Select merchant--',
    visible: true,
  },

  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Status',
    model: null,
    options: [
      {
        id: 2,
        description: 'Approved/Executed',
      },
      {
        id: 3,
        description: 'Disapproved',
      },
    ],
    reduce: 'id',
    selectText: 'description',
    cols: 12,
    placeholder: '--Select status--',
    visible: false,
  },
];
