export default [
  {
    key: 'client',
    label: 'Client',
    visible: true,
  },
  {
    key: 'settlement_date',
    label: 'settlement date',
    visible: true,
  },
  {
    key: 'merchant',
    label: 'Merchant',
    visible: true,
  },
  {
    key: 'amount',
    label: 'amount',
    visible: true,
  },
  {
    key: 'type',
    label: 'Type',
    thClass: 'text-center',
    tdClass: 'text-center',
    visible: true,
  },
  {
    key: 'created_by',
    label: 'Request by',
    thClass: 'text-center',
    tdClass: 'text-center',
    visible: true,
  },
  {
    key: 'actions',
    label: 'actions',
    thClass: 'text-center',
    tdClass: 'text-center',
    visible: true,
  },
  {
    key: 'status',
    label: 'status',
    thClass: 'text-center',
    tdClass: 'text-center',
    visible: false,
  },
  {
    key: 'executed',
    label: 'executed by',
    thClass: 'text-center',
    tdClass: 'text-center',
    visible: false,
  },
];
