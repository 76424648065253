<template>
  <b-modal
    v-model="onControl"
    size="default"
    header-bg-variant="danger"
    centered
    @hidden="closeModal"
  >
    <template #modal-title>
      <strong class="text-uppercase">DISAPPROVE {{ request.type_request }} TO CLIENT '{{ request.client_name }}'</strong>
    </template>
    <b-card class="shadow-none pb-0 mb-0">
      <validation-observer ref="formDisapproveRequest">
        <b-row>
          <b-col cols="12">
            <validation-provider
              v-slot="{errors}"
              name="observation"
              rules="required|max:255"
            >
              <b-form-group
                label="Observations"
                label-for="observations"
              >
                <b-form-textarea
                  id="observations"
                  v-model="observations"
                  name="observations"
                  class="text-area-box roboto-class"
                  cols="30"
                  rows="5"
                  max-length="255"
                  placeholder="What is the reason?"
                  :state="errors[0] ? false : null"
                  :class="errors[0] ? 'border-danger' : ''"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
    <template #modal-footer>
      <b-button
        variant="danger"
        @click="disapproveRequest"
      >
        <feather-icon
          icon="XIcon"
          class="text-white"
          style="margin-right: 1px"
        />
        Disapproved
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import chargeBackService from '@/views/commons/components/charge-back_v2/service/charge.back.service';
import { mapGetters } from 'vuex';

export default {
  props: {
    request: {
      type: Object,
      default: () => {},
      require: true,
    },
  },
  data() {
    return {
      onControl: false,
      observations: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  mounted() {
    this.onControl = true;
  },
  methods: {
    closeModal() {
      this.onControl = false;
      this.$emit('closeModal');
    },
    async disapproveRequest() {
      try {
        const validateForm = await this.$refs.formDisapproveRequest.validate();
        if (!validateForm) {
          return;
        }
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) {
          return;
        }
        this.addPreloader();
        const params = {
          uuid_transaction: this.request.uuid_transaction,
          user_id: this.currentUser.user_id,
          status: 3, // REQUEST DISAPPROVE
          observations: this.observations,
          amount: 0,
          user_name: this.currentUser.fullName,
          typeRequest: this.request.type_request,
        };
        const { result, message } = await chargeBackService.insertRequestToTransaction(params);
        this.removePreloader();
        if (result === 1) {
          this.showSuccessSwal();
          this.$emit('refresh');
        } else {
          this.showWarningSwal('Info', message);
        }
      } catch (error) {
        console.log('error to disapproveRequest ', error);
      }
    },
  },
};
</script>
