<template>
  <div>
    <b-card>
      <filter-slot
        :filter="filters"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        :send-multiple-sms="false"
        :change-columns-by-client="true"
        @reload="$refs['refTableList'].refresh()"
      >
        <template #table>
          <b-table
            ref="refTableList"
            no-provider-filtering
            :items="requestProvider"
            :fields="tabFields"
            primary-key="id"
            table-class="text-nowrap"
            show-empty
            sticky-header="70vh"
            :busy.sync="isBusy"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(client)="data">
              <span class="text-primary">{{ data.item.client_name }}</span>
              <div v-if="data.item.account">
                <router-link
                  class
                  :to="{
                    name: 'management-dashboard',
                    params: {
                      idClient: data.item.client_account_id,
                    },
                  }"
                  target="_blank"
                >
                  {{ data.item.account }}
                </router-link>
              </div>
              <div
                v-else
                class="text-danger"
              >
                Lead
              </div>
            </template>
            <template #cell(settlement_date)="data">
              <span> {{ data.item.program_name }}</span><br>
              <span>{{ data.item.settlement_date | myGlobalDay }}</span>
            </template>
            <template #cell(merchant)="data">
              <span :class="colorMerchant[data.item.merchant]">{{ data.item.merchant_name }}</span><br>
              <span> AUTH CODE: {{ data.item.auth_code }}</span><br>
              <span> CARD: {{ data.item.card_number }}</span><br>
            </template>
            <template #cell(amount)="data">
              <span>Transaction Amount: $ {{ data.item.amount }}</span><br>
              <span v-if="data.item.status_request_transaction === 3">
                {{ data.item.void === 1 ? 'Void':'Refund' }} amount : $ -
              </span>
              <strong
                v-else
                :class="data.item.request_amount ? 'text-success':'text-warning'"
              >
                {{ data.item.void === 1 ? 'Void':'Refund' }} amount : $ {{ data.item.request_amount || '-' }}
              </strong>
            </template>
            <template #cell(type)="data">
              <strong
                v-if="data.item.void === 1"
                style="font-size: 13px;padding: 5px 10px;"
                class="bg-primary rounded text-white"
              >
                {{ data.item.request_amount && data.item.amount !== data.item.request_amount ? ' partial ':'' }} void
              </strong>
              <strong
                v-else
                class="bg-info rounded text-white"
                style="font-size: 13px;padding: 5px 10px;"
              >
                {{ data.item.request_amount && data.item.amount !== data.item.request_amount ? ' partial ':'' }} refund
              </strong><br>
              <tabler-icon
                v-if="data.item.comment"
                v-b-tooltip.hover
                v-b-tooltip.hover.bottom
                title="comment"
                icon="AlignJustifiedIcon"
                size="15"
                class="text-primary cursor-pointer mt-1 hover-me"
                @click="showText('comment',data.item.comment)"
              />
            </template>
            <template #cell(created_by)="data">
              <b> {{ JSON.parse(data.item.request_by)[0].user }} </b><br>
              <span>{{ JSON.parse(data.item.request_by)[0].date | myGlobalDay }}</span>
            </template>
            <template #cell(actions)="data">
              <tabler-icon
                v-if="data.item.void"
                :id="`t-v-${data.index}`"
                v-b-tooltip.hover.v-danger
                v-b-tooltip.hover.top
                title="VOID"
                icon="CoinIcon"
                class="mr-1 hover-me cursor-pointer text-danger"
                size="20"
                @click="openVoidRefundModal(data.item, 1)"
              />
              <feather-icon
                v-else-if="data.item.refund"
                :id="`t-r-${data.index}`"
                v-b-tooltip.hover.v-success
                v-b-tooltip.hover.top
                title="REFUND"
                icon="ClockIcon"
                class="mr-1 hover-me text-success cursor-pointer"
                size="19"
                @click="openVoidRefundModal(data.item, 2)"
              />
              <!--cancel the request: void o refund-->
              <feather-icon
                :id="`t-r-${data.index}`"
                v-b-tooltip.hover.v-danger
                v-b-tooltip.hover.top
                title="DISAPPROVE"
                icon="XIcon"
                class="mr-1 hover-me text-danger cursor-pointer border-danger border rounded-circle"
                size="19"
                @click="cancelRequest(data.item,data.item.void === 1 ? 'void' : 'refund')"
              />
            </template>
            <template
              v-if="tab===2"
              #cell(status)="data"
            >
              <div v-if="data.item.status_request_transaction===3">
                <tabler-icon
                  v-if="data.item.observations"
                  v-b-tooltip.hover
                  title="observation"
                  icon="AlertCircleIcon"
                  size="15"
                  class="text-danger cursor-pointer mr-1 hover-me"
                  @click="showText('observation',data.item.observations)"
                />
                <strong
                  style="padding: 5px 10px;"
                  class="bg-danger rounded text-white"
                >disapproved</strong>
              </div>
              <strong
                v-else-if="data.item.date_to_pay && data.item.executed_at ===null"
                style="padding: 5px 10px;"
                class="bg-warning rounded text-white"
              >This will be executed at: {{data.item.date_to_pay | myGlobalDay}}</strong>
              <strong
                  v-else
                  style="padding: 5px 10px;"
                  class="bg-success rounded text-white"
              >Executed</strong>
            </template>
            <template
              v-if="tab===2"
              #cell(executed)="data"
            >
              <b> {{ data.item.created_by }} </b><br>
              <span>{{ data.item.created_at | myGlobalDay }}</span>
            </template>
          </b-table>
        </template>
      </filter-slot>
    </b-card>
    <!-- MODAL REFUND O VOID-->
    <void-refund-modal
      v-if="voidRefundModalOn"
      :void-refund-data="voidRefundData"
      :type-void-refund="typeVoidRefund"
      @refresh="refresh"
      @close="closeVoidRefundModal"
    />
    <!--MODAL DISAPPROVED REFUND O VOID-->
    <modal-disapprove-request
      v-if="showModalDisapproved"
      :request="selectedTransaction"
      @closeModal="showModalDisapproved= false"
      @refresh="successDisapproved"
    />
    <!--Modal Show Text-->
    <b-modal
      v-model="showModalText"
      size="md"
      centered
      @hidden="closeModalText"
    >
      <template #modal-title>
        <strong class="text-uppercase">{{ paramsText.typeText }}</strong>
      </template>
      <b-row>
        <b-col cols="12">
          <b-form-textarea
            v-if="paramsText.value"
            v-model="paramsText.value"
            class="text-area-box roboto-class"
            name="text"
            cols="30"
            rows="5"
            disabled
            placeholder="What is the reason?"
          />
          <h4
            v-else
            class="text-center"
          >
            {{ `No ${paramsText.typeText}` }}
          </h4>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div />
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import fields from '@/views/management/views/requests-transactions/data/request-transactions.fields';
import filters from '@/views/management/views/requests-transactions/data/request-transaction.filters';
import ServicesRequestTransactions from '@/views/management/views/requests-transactions/services/request-transactions.services';
import chargeBackService from '@/views/commons/components/charge-back_v2/service/charge.back.service';
import VoidRefundModal from '@/views/commons/components/clients/dashboard/options/pay-module/modals/VoidRefundModal.vue';
import ModalDisapproveRequest from '@/views/management/views/requests-transactions/modals/ModalDisapproveRequest.vue';
import store from '@/store';

export default {
  components: {
    VoidRefundModal,
    ModalDisapproveRequest,
  },
  data() {
    return {
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search by client name ...',
        model: '',
      },
      totalRows: 0,
      startPage: 0,
      toPage: 0,
      paginate: {
        currentPage: 1,
        perPage: 100,
      },
      isBusy: false,
      sortBy: 'created_at',
      sortDesc: true,
      colorMerchant: ['text-primary', 'text-info', 'text-warning', 'text-success'],
      voidRefundModalOn: false,
      voidRefundData: {},
      typeVoidRefund: null,
      selectedTransaction: {
        uuid_transaction: null,
        client_name: null,
        type_request: null,
      },
      showModalDisapproved: false,
      filters,
      fields,
      showModalText: false,
      paramsText: {
        typeText: null,
        value: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    tab() {
      return this.$route.meta.tab;
    },
    tabFields() {
      return this.fields.filter(item => item.visible === true);
    },
  },
  async mounted() {
    this.fields[6].visible = this.tab === 1;
    this.fields[7].visible = this.tab === 2;
    this.fields[8].visible = this.tab === 2;
    this.filters[2].visible = this.tab === 2;
    await this.getPrograms();
    await this.getAllMerchants();
  },
  methods: {

    async getPrograms() {
      try {
        const { data } = await ServicesRequestTransactions.getPrograms();
        this.filters[0].options = data;
      } catch (error) {
        console.log('error get programs', error);
        this.showErrorSwal();
      }
    },
    async getAllMerchants() {
      try {
        const { data } = await ServicesRequestTransactions.getAllMerchants();
        this.filters[1].options = data;
      } catch (error) {
        console.log('Something went wrong on getAllMerchants:', error);
        throw error;
      }
    },
    async requestProvider() {
      try {
        const status = !this.filters[2].model ? '2,3' : this.filters[2].model;
        this.isBusy = true;
        const params = {
          perpage: this.paginate.perPage,
          npage: this.paginate.currentPage,
          status: this.tab === 2 ? status : '1',
          program_id: this.filters[0].model,
          search: this.filterPrincipal.model,
          merchant_id: this.filters[1].model,
        };
        const { data } = await ServicesRequestTransactions.getRequestTransactions(params);
        this.isBusy = false;
        this.totalRows = data.total || 0;
        this.startPage = data.from || 0;
        this.toPage = data.to || 0;

        return data.data;
      } catch (error) {
        console.log('error requestProvider ', error);
      }
      return [];
    },
    openVoidRefundModal(data, type) {
      this.typeVoidRefund = type;
      this.voidRefundData = {
        transaction_id: data.transaction_id,
        merchant: data.merchant,
        amount: data.amount,
        client_name: `${data.client_name}`,
        settlement_date: data.settlement_date,
        uuid_transaction: data.uuid_transaction,
      };
      this.voidRefundModalOn = true;
    },
    closeVoidRefundModal() {
      this.voidRefundModalOn = false;
    },
    async refresh( information) {
      this.refreshTableList();
      // only insert without show alert
      try {
        const params = {
          uuid_transaction: this.voidRefundData.uuid_transaction,
          user_id: this.currentUser.user_id,
          status: 2, // when you execute a 'void' or 'refund', then you accepted the request
          observations: null,
          amount : information.amount,
          user_name: this.currentUser.fullName,
          typeRequest: this.typeVoidRefund,
          request_id: information.id
        };
        const { result } = await chargeBackService.insertRequestToTransaction(params);
        if (result === 1) {
          this.updateCounter();
        }
      } catch (error) {
        console.log('error insertRequestToTransaction (only transaction)', error);
      }
    },
    cancelRequest({ uuid_transaction, client_name }, typeRequest) {
      this.selectedTransaction.uuid_transaction = uuid_transaction;
      this.selectedTransaction.client_name = client_name;
      this.selectedTransaction.type_request = typeRequest;
      this.showModalDisapproved = true;
    },
    async updateCounter() {
      const { data } = await ServicesRequestTransactions.countPendingRequestOfTransaction();
      const payload = {
        routeName: 'request-transactions',
        tag: data.data,
      };
      store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
    },
    async successDisapproved() {
      this.showModalDisapproved = false;
      await this.updateCounter();
      this.refreshTableList();
    },
    refreshTableList() {
      this.$refs.refTableList.refresh();
    },
    showText(typeText, text) {
      this.paramsText.typeText = typeText;
      this.paramsText.value = text;
      this.showModalText = true;
    },
    closeModalText() {
      this.showModalText = false;
    },
  },
};
</script>
<style scope>
.hover-me{
  transition: 0.3s ease-in-out;
}
.hover-me:hover{
  transform: scale(1.3);
}
</style>
